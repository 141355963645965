'use strict';
import Swiper, { Navigation } from 'swiper';

export const timeline = {
  init: function (selector) {
    selector = selector ?? '#timeline';

    Swiper.use([Navigation]);
    window.addEventListener('load', this.setup(selector));
  },
  setup: function (selector) {
    const windowWidth = $(window).innerWidth();
    let swiperModal = null;

    if (windowWidth > 576) {
      // eslint-disable-next-line no-unused-vars
      const swiper = new Swiper(selector + ' .swiper-container', {
        slidesPerView: 'auto',
        spaceBetween: 0,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      });
    }

    $(selector + ' .event').on('keydown', function (event) {
      if (event.keyCode === 13) {
        $(this).trigger('click');
      }
    });

    $(selector + ' .event').on('click', function () {
      var slide = $(this).data('slide');
      var $timelineModal = $('#timelineModal');

      if (!swiperModal) {
        $timelineModal.on('shown.bs.modal', function () {
          swiperModal = new Swiper('#timelineModal .swiper-container', {
            initialSlide: slide
          });

          $timelineModal.off('shown.bs.modal');
        });
      }

      $timelineModal.modal('show');

      if (swiperModal) {
        swiperModal.slideTo(slide);
      }
    });
  }
};
