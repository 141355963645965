export const back2top = {
  init: function () {
    const $backToTop = $('.js-back2top');

    // Back to top / appear on scroll
    if ($backToTop.length) {
      $backToTop.hide();
      $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
          $backToTop.fadeIn();
        } else {
          $backToTop.fadeOut();
        }
      });
    }
    // Back to top / smooth scroll
    $backToTop.click(function (e) {
      e.preventDefault();
      const target = $('body');
      $('html, body').animate({
        scrollTop: target.offset().top
      }, 500, function () {
        target.attr('tabindex', '-1').focus(); // Set focus on body
      });
    });
  }
};
