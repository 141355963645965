import Highcharts from 'highcharts';
import addExporting from 'highcharts/modules/exporting';
import addExportData from 'highcharts/modules/export-data';
import addAccessibility from 'highcharts/modules/accessibility';

addExporting(Highcharts);
addExportData(Highcharts);
addAccessibility(Highcharts);

const enquire = require('enquire.js');
const screenDownMD = 'screen and (max-width:991.98px)';
const screenUpLG = 'screen and (min-width:992px)';

let selected_periods = [];
let checkedFilters = [];
let chartOverview;
let chartData;

const $html = $('html');
const locale = $html.attr('lang');
const $form_overview = $('#employment-overview-form');
const $form_data = $('#employment-data-form');
const $form_archives = $('#archives-form');
const statsJsonPath = $html.data('dwh-data');

const texts = {
  delete: { // texte de l’icône poubelle pour la suppression d'un quarter sélectionné dans le formulaire
    fr: 'Supprimer',
    nl: 'Verwijderen'
  },
  // textes des graphiques
  columnMultiple: {
    fr: 'Graphique en colonnes avec {numSeries} séries de données',
    nl: 'Staafdiagram met {numSeries} gegevensreeksen'
  },
  xAxisDescriptionSingular: {
    fr: 'Le graphique a 1 axe X affichant {names[0]}. {ranges[0]}',
    nl: 'Het diagram heeft één X-as waarop {names[0]} wordt weergegeven. {ranges[0]}'
  },
  yAxisDescriptionSingular: {
    fr: 'Le graphique a 1 axe Y affichant {names[0]}. {ranges[0]}',
    nl: 'Het diagram heeft één Y-as waarop {names[0]} wordt weergegeven. {ranges[0]}'
  },
  rangeFromTo: {
    fr: 'De {rangeFrom} à {rangeTo}.',
    nl: 'Van {rangeFrom} tot {rangeTo}.'
  },
  emptyChart: {
    fr: 'Pas de données',
    nl: 'Geen gegevens'
  },
  viewAsDataTableButtonText: {
    fr: 'Voir le tableau',
    nl: 'See table'
  }
};

const exportOptions = {
  chart: {
    width: 1000,
    marginRight: 200
  },
  legend: {
    layout: 'vertical',
    align: 'right',
    maxHeight: 300,
    itemMarginTop: 0,
    verticalAlign: 'middle',
    floating: false,
    width: 150,
    backgroundColor: '#FFFFFF',
    itemStyle: {
      fontWeight: 'normal',
      fontSize: '9px',
      textOverflow: undefined,
      'word-wrap': 'break-word'
    },
    navigation: {
      enabled: false
    }
  }
};

const overviewGlobalOptions = {
  chart: { height: 400 },
  legend: {
    layout: 'vertical',
    align: 'right',
    maxHeight: 300,
    verticalAlign: 'middle',
    width: 230,
    backgroundColor: '#FFFFFF',
    itemStyle: '{ "word-wrap": "break-word"}'
  },
  responsive: {
    rules: [{
      condition: {
        maxWidth: 575.98
      },
      chartOptions: {
        legend: {
          align: 'left',
          verticalAlign: 'bottom',
          layout: 'horizontal'
        },
        yAxis: {
          labels: {
            align: 'left',
            x: 0,
            y: -5
          },
          title: {
            text: null
          }
        },
        subtitle: {
          text: null
        },
        credits: {
          enabled: false
        }
      }
    }]
  }
};

export const stats = {
  init: function () {
    // console.log('init charts');
    this.form_actions();

    // show overview graph only if first tab is open
    if ($form_overview.length && (window.location.hash == '' || window.location.hash == '#introduction')) {
      stats.overview_chart_init();
    } else {
      $('#pageTabs #tab-introduction').on('shown.bs.tab', function (e) {
        stats.overview_chart_init();
      });
    }

    if ($form_archives.length) {
      this.archives();
    }
  },
  form_actions: function () {
    // this.test();
    this.add_periods();
    this.remove_periods();
    this.form_data_change();
    this.submit_form();
  },
  add_periods: function () {
    $('#field_periods button').click(function (e) {
      // const year = $('#field_periods #year').val();
      // const quarter = $('#field_periods #quarter').val();
      // const period = year+quarter;
      const period = $('#field_periods #period').val();
      const period_text = $('#field_periods #period option:selected').text();

      if (period && jQuery.inArray(period, selected_periods) == -1) {
        selected_periods.push(period);
        selected_periods.sort();

        const selected_quarter = '<li class="list-inline-item mr-3">' + period_text + ' <button data-remove="' + period + '" class="btn btn-link border-0 p-0 ml-2" type="button"><i class="fas fa-trash-alt" aria-hidden="true"></i> <span class="sr-only">' + texts.delete[locale] + ' ' + period_text + '</span></button>';
        $('#selected_quarters ul').append(selected_quarter);

        $form_data.find('select').trigger('change');
      }
    });
  },
  remove_periods: function () {
    $(document).on('click', '#selected_quarters button', function (e) {
      const target = $(this).attr('data-remove');
      // remove from Array
      selected_periods = jQuery.grep(selected_periods, function (value) {
        return value != target;
      });
      // remove <li>
      $(this).parent('li').remove();

      $form_data.find('select#period').trigger('change');
    });
  },
  form_data_change: function () {
    $form_data.find('select').on('change', function () {
      let fieldsHaveValue = true;

      $form_data.find('option:selected, input:checked').each(function (i, e) {
        const hasValue = $(e).val();
        if (!hasValue) {
          fieldsHaveValue = false;
        }
      });

      if (fieldsHaveValue && selected_periods.length > 0) {
        $form_data.find('[type="submit"]').removeAttr('disabled');
      } else {
        $form_data.find('[type="submit"]').attr('disabled', true);
      }
    });
  },
  test: function () {
    console.log('test ok');
    $('#field_periods button').click(function (e) {
      // const json_url = '/bundles/emsch_assets/html/activities2.json';
      const json_url = '/bundles/emsch_assets/html/age.json';

      stats.get_data(json_url);
      $('.chart-results').removeClass('d-none');
    });
  },
  submit_form: function () {
    $form_overview.submit(function (e) {
      e.preventDefault();
      if (chartOverview) {
        stats.loader(chartOverview, 'show');
      }
      stats.overview_chart_init();
    });

    $form_data.submit(function (e) {
      e.preventDefault();
      const theme = $(this).find('[name="theme"]').val();
      const mesure = $(this).find('[name="mesure"]:checked').val();
      const calculation = $(this).find('[name="calculation"]:checked').val();

      let args = '?mesure=' + mesure + '&theme=' + theme + '&calculation=' + calculation;
      $.each(selected_periods, function (i, period) {
        args += '&periods%5B%5D=' + period;
      });

      const json_url = statsJsonPath + args;

      stats.get_data(json_url);
      stats.csvLink(args);

      if (chartData) {
        stats.loader(chartData, 'show');
      }

      // show div
      $('.chart-results').removeClass('d-none');
    });
  },
  csvLink: function (args) {
    const $CSVbutton = $('#downloadCSV');
    if ($CSVbutton.length) {
      $CSVbutton.attr('href', $CSVbutton.attr('data-csv') + args);
    }
  },
  overview_chart_init: function () {
    const mesure = $form_overview.find('[name="mesure-overview"]:checked').val();
    const theme = $form_overview.find('[name="theme"]').val();

    let json_url = statsJsonPath + '?mesure=' + mesure + '&theme=' + theme + '&calculation=SUM';
    json_url += $form_overview.attr('data-periods');

    // var json_url = '/bundles/emsch_assets/html/activities2.json';

    stats.get_overview_data(json_url);
  },
  get_overview_data: function (json_url) {
    // console.log('getdata-overview from '+json_url);

    if ($('div').hasClass('highcharts-overview')) {
      if (chartOverview) {
        chartOverview.showLoading();
      }

      $.getJSON(json_url, function (data) {
        // merge options from JSON + option for overview
        const overviewParams = $.extend(true, data.chartsOptions, overviewGlobalOptions);
        // merge options with series
        const overviewOptions = $.extend(true, { series: stats.overview_define_series(data) }, overviewParams);

        chartOverview = Highcharts.chart('chart-overview', overviewOptions);

        $('#overview-table-pane').empty().append(data.chartsTable);

        stats.loader(chartOverview, 'hide');
        chartOverview.hideLoading();
      });
    }
  },
  overview_define_series: function (data) {
    // console.log("define series overview");
    const overview_series = [];
    // create new series
    $.each(data.chartsData, function (i, chartsData) {
      if (chartsData.data) {
        const overview_serie = {
          name: chartsData.label,
          data: chartsData.data
        };
        overview_series.push(overview_serie);
      } else if (chartsData.children) {
        $.each(chartsData.children, function (j, child) {
          if (child.data) {
            const seriesName = chartsData.label + ' / ' + child.label;
            const overview_serie = {
              name: seriesName,
              data: child.data
            };
            overview_series.push(overview_serie);
          }
        });
      }
    });
    return overview_series;
  },
  get_data: function (json_url) {
    // console.log('getdata from '+json_url);
    if ($('div').hasClass('highchart')) {
      $.getJSON(json_url, function (data) {
        chartData = Highcharts.chart('chart-data', data.chartsOptions);

        $('#table-pane').empty().append(data.chartsTable);

        $('.charts-wapper [role="tab"]').click(function (e) {
          if ($(e.target).attr('id') == 'graph-tab') {
            $('#filters fieldset').each(function () {
              $(this).removeAttr('disabled');
            });
          }
          if ($(e.target).attr('id') == 'table-tab') {
            $('#filters fieldset').each(function () {
              $(this).attr('disabled', true);
            });
          }
        });

        // create filters based on JSON
        stats.create_filters(data);

        // update chart with filter
        stats.filter_chart(data);
      });

      stats.export_actions();
    }
  },
  collapse_filters: function (filter) {
    const $filterCollapseLink = $('#collapse-filters-link');

    // replace collapse link with title for desktop
    enquire.register(screenUpLG, {
      match: function () {
        if ($filterCollapseLink.is(':visible')) {
          // hide link and add its contents after
          $filterCollapseLink.hide().after($filterCollapseLink.html());
        }
      },
      unmatch: function () {
        const titleOnly = $filterCollapseLink.next('h2');

        // remove its content and show link again
        titleOnly.remove();
        $filterCollapseLink.show();
      }
    });
  },
  create_filters: function (data) {
    // console.log("create filters");

    // clear all
    const fieldsets = [];
    checkedFilters = [];
    $('#collapse-filters').children('fieldset').remove();

    // create fieldsets based on filter labels
    if (data.chartsLabels) {
      $.each(data.chartsLabels, function (i, legend) {
        fieldsets[i] = $('<fieldset>', { html: '<legend>' + legend + '</legend>' });
      });

      // create checkboxes if does not exist
      $.each(data.chartsData, function (i, chartsData) {
        if (chartsData.label) {
          if (jQuery.inArray(chartsData.id, checkedFilters) == -1) {
            checkedFilters.push(chartsData.id);
            const formCheck = stats.create_checkbox(chartsData);
            fieldsets[0].append(formCheck);
          }
        }

        if (chartsData.children) {
          $.each(chartsData.children, function (j, child) {
            if (child.label) {
              if (jQuery.inArray(child.id, checkedFilters) == -1) {
                checkedFilters.push(child.id);
                const formCheck = stats.create_checkbox(child);
                fieldsets[1].append(formCheck);
              }
            }
          });
        }
      });

      // add filters
      $.each(fieldsets, function (i, fieldset) {
        if (fieldset.find('[type="checkbox"]').length) {
          $('#collapse-filters').append(fieldset);
        }
      });
    }

    const $filterTitle = $('#filters h2');

    if ($filterTitle.length) {
      this.collapse_filters($filterTitle);
    }

    // add series to chart
    stats.create_series(data);
  },
  create_checkbox: function (data) {
    let checkBox = '<div class="custom-control custom-checkbox">';
    checkBox += '<input class="custom-control-input" type="checkbox" value="' + data.id + '" id="' + data.id + '" checked>';
    checkBox += '<label class="custom-control-label" for="' + data.id + '">';
    checkBox += data.label;
    checkBox += '</label>';
    checkBox += '</div>';

    return checkBox;
  },
  create_series: function (data) {
    // console.log("create series");

    // remove all series if any
    while (chartData.series.length > 0) {
      chartData.series[0].remove(false);
    }

    // create new series
    $.each(data.chartsData, function (i, chartsData) {
      // if no filters... show the charts
      const noFilters = typeof data.chartsLabels === 'undefined';

      if (jQuery.inArray(chartsData.id, checkedFilters) !== -1 || noFilters) {
        if (chartsData.data) {
          chartData.addSeries({
            name: chartsData.label,
            data: chartsData.data
          }, false);
        } else if (chartsData.children) {
          $.each(chartsData.children, function (j, child) {
            if (jQuery.inArray(child.id, checkedFilters) !== -1 || noFilters) {
              if (child.data) {
                const seriesName = chartsData.label + ' / ' + child.label;

                chartData.addSeries({
                  name: seriesName,
                  data: child.data
                }, false);
              }
            }
          });
        }
      }
    });

    chartData.redraw();
    stats.loader(chartData, 'hide');
    chartData.hideLoading();
  },
  filter_chart: function (data) {
    $('#filters [type="checkbox"]').on('change', function () {
      chartData.showLoading();

      // clear Array
      checkedFilters = [];

      setTimeout(function () {
        $('#filters [type=\'checkbox\']:checked').each(function () {
          checkedFilters.push($(this).val());
        });

        // update series
        stats.create_series(data);
      }, 100);
    });
  },
  export_actions: function () {
    $('[data-export]').click(function (e) {
      // in case the doc has multiple charts
      const closestChart = $(this).parents('.charts-wapper').find('.highchart').highcharts();
      const action = $(this).attr('data-export');

      switch (action) {
        case 'downloadCSV':
          closestChart.downloadXLS();
          break;
        case 'downloadPDF':
          closestChart.exportChart({ type: 'application/pdf' }, exportOptions);
          break;
      }
    });
  },
  loader: function (chart, display) {
    const $loader = $(chart.renderTo).prev('.loader');
    if (display == 'hide') {
      $loader.removeClass('d-flex').addClass('d-none');
    }
    if (display == 'show') {
      $loader.removeClass('d-none').addClass('d-flex');
    }
  },
  archives: function () {
    // Laurens
    $('select#arch_period').on('change', function () {
      const period = this.value;

      if (period == '') {
        $('#brochure, #spreadsheet').attr('disabled', 'disabled');
        return;
      }

      $('#brochure, #spreadsheet, #zip').removeAttr('disabled').prop('checked', false);

      const brochureLink = $('#' + period).attr('data-brochure');
      const spreadsheetLink = $('#' + period).attr('data-spreadsheet');
      const zipLink = $('#' + period).attr('data-zip');

      $('#brochure').val(brochureLink);
      $('#spreadsheet').val(spreadsheetLink);
      $('#zip').val(zipLink);

      if (brochureLink == '') {
        $('#brochure').attr('disabled', 'disabled');
      }

      if (spreadsheetLink == '') {
        $('#spreadsheet').attr('disabled', 'disabled');
      }
    });

    $('#downloader', $form_archives).on('click', function (e) {
      e.preventDefault();

      const brochureLink = $('#brochure').val();
      const spreadsheetLink = $('#spreadsheet').val();
      const zipLink = $('#zip').val();

      if ($('#brochure').is(':checked') && !$('#spreadsheet').is(':checked')) {
        var url = brochureLink;
        var link = document.createElement('a');
        link.href = url;
        link.download = url.substr(url.lastIndexOf('/') + 1);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      if ($('#spreadsheet').is(':checked') && !$('#brochure').is(':checked')) {
        var url = spreadsheetLink;
        var link = document.createElement('a');
        link.href = url;
        link.download = url.substr(url.lastIndexOf('/') + 1);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      if ($('#brochure').is(':checked') && $('#spreadsheet').is(':checked')) {
        var url = zipLink;
        var link = document.createElement('a');
        link.href = url;
        link.download = url.substr(url.lastIndexOf('/') + 1);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  }
};

// global options
Highcharts.setOptions({
  chart: {
    height: 550,
    style: {
      fontFamily: '"Wes FY", "Roboto", "Helvetica Neue", "Arial", "Noto Sans", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
      fontSize: '14px'
    }
    // events:{
    //   render: function() { console.log('rendered !') }
    // }
  },
  colors: ['#E3A426', '#C8401E', '#8AB53A', '#1A579E', '#003f5c', '#2f4b7c', '#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#ffa600', '#247f8d'],
  title: {
    text: null
  },
  xAxis: {
    crosshair: true
  },
  yAxis: {
    title: {
      text: undefined
    },
    stackLabels: {
      enabled: true,
      style: {
        fontWeight: 'bold',
        color: 'gray'
      }
    }
  },
  credits: {
    enabled: false
  },
  exporting: {
    enabled: false
  },
  lang: {
    decimalPoint: ',',
    thousandsSep: '.',
    numericSymbols: null, // otherwise by default ['k', 'M', 'G', 'T', 'P', 'E']
    accessibility: {
      axis: {
        rangeFromTo: texts.rangeFromTo[locale],
        xAxisDescriptionSingular: texts.xAxisDescriptionSingular[locale],
        yAxisDescriptionSingular: texts.yAxisDescriptionSingular[locale]
      },
      chartTypes: {
        columnMultiple: texts.columnMultiple[locale],
        emptyChart: texts.emptyChart[locale]
      },
      table: {
        viewAsDataTableButtonText: texts.viewAsDataTableButtonText[locale]
      }
    }
  },
  accessibility: {
    screenReaderSection: {
      // beforeChartFormat: "<h5>{chartTitle}</h5><div>{typeDescription}</div><div>{chartSubtitle}</div><div>{chartLongdesc}</div><div>{playAsSoundButton}</div><div>{viewTableButton}</div><div>{xAxisDescription}</div><div>{yAxisDescription}</div><div>{annotationsTitle}{annotationsList}</div>",
      onViewDataTableClick: function (e) {
        e.preventDefault();
        const tabTableID = $(this).closest('[role="tabpanel"]').next('[role="tabpanel"]').attr('aria-labelledby');
        $('button#' + tabTableID).trigger('click');
      }
    }
  },
  legend: {
    layout: 'horizontal',
    maxHeight: 300,
    itemDistance: 20,
    itemMarginTop: 5,
    itemMarginBottom: 5,
    navigation: {
      activeColor: '#247f8d',
      animation: true,
      arrowSize: 12,
      enabled: true,
      inactiveColor: '#cccccc'
    }
  }
});
