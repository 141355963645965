export const bootstrapModalVideo = {
  init: function () {
    let videoSrc,
      videoTitle,
      videoTranscript;
    $('.js-video-modal-trigger').click(function () {
      videoSrc = $(this).data('src');
      videoTitle = $(this).data('transcript-text');
      videoTranscript = $(this).data('video-transcript');
    });

    const $iframe = $('<iframe class="embed-responsive-item" src="" id="js-video-iframe" title="js-video-iframe" allowscriptaccess="always" allow="autoplay" allowfullscreen></iframe>');
    const $transcriptLink = $('#video-transcript-link');
    $('#js-modal-video').on('shown.bs.modal', function (e) {
      $iframe.attr('src', `${videoSrc}?autoplay=1&modestbranding=1&showinfo=0&rel=0`);
      if (!$iframe.hasClass('loaded')) {
        const $embed = $(this).find('.embed-responsive');
        $embed.append($iframe);
        $iframe.show();
      }

      if (videoTitle && videoTranscript) {
        $transcriptLink.attr('href', videoTranscript);
        $transcriptLink.html(videoTitle);
        $transcriptLink.show();
      } else {
        $transcriptLink.hide();
      }
    }).on('hide.bs.modal', function (e) {
      $iframe.attr('src', videoSrc);
      $transcriptLink.hide();
    });
  }
};
