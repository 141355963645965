'use strict';

export const spreadLink = {
  init: function (selector) {
    selector = selector ?? '.js-spread-link';
    window.addEventListener('load', this.setup(selector));
  },
  setup: function (selector) {
    const spreadLinks = document.querySelectorAll(selector);
    spreadLinks.forEach(function (spreadLink) {
      spreadLink.addEventListener('click', function () {
        const link = spreadLink.querySelector('a');
        const href = link.getAttribute('href');
        const target = link.getAttribute('target');

        if (target) {
          window.open(href, target);
          return;
        }

        window.location = href;
      });
    });
  }
};
