import './vendor/jquery';
import 'bootstrap';

import showAdminMenu from '@elasticms/admin-menu';
import toc from './modules/toc';
import initCollapse from './modules/content-collapse';
import wysiwygPostProcessing from './modules/wysiwygPostProcessing.js';
import accessibility from './modules/accessibility.js';
import {
  back2top,
  barometerSlider,
  bootstrapModalVideo,
  cookiesBanner,
  heroSlider,
  mainNavigation,
  mobileNavigation,
  pageTabs,
  offices,
  searchModal,
  spreadLink,
  stats,
  timeline
} from './modules';

import '../styles/app.scss';

$(function () {
  accessibility();
  back2top.init();
  barometerSlider.init('#barometer-swiper');
  bootstrapModalVideo.init();
  cookiesBanner.init('#cookiesBanner', 'cookieBanner');
  heroSlider.init('hero-swiper');
  initCollapse();
  mainNavigation.init('.main-nav');
  mobileNavigation.init('.js-change-location');
  offices.init('.offices select');
  pageTabs.init();
  searchModal.init('#searchModal');
  showAdminMenu();
  spreadLink.init();
  stats.init();
  timeline.init('#timeline');
  toc();
  wysiwygPostProcessing();
});
