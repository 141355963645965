export default function wysiwygPostProcessing () {
  $('.wysiwyg-content table').each(function () {
    const $parent = $(this).parent();
    if (!$parent.hasClass('.table-responsive')) {
      $(this).wrap('<div class="table-responsive"></div>');
    }

    $(this).addClass('table table-striped table-bordered');
    $(this).find('thead').addClass('thead-light');
  });

  $('.wysiwyg-content h2').addClass('c-text-with-dash c-text-with-dash--sm');
}
