'use strict';

export const mainNavigation = {
  init: function (selector) {
    selector = selector ?? '.main-nav';
    window.addEventListener('load', this.setup(selector));
  },
  setup: function (selector) {
    $(selector + ' .dropdown').on('show.bs.dropdown', function () {
      const height = $(this).find('.dropdown-menu').outerHeight();
      $('header.navbar').css('margin-bottom', height + 'px');
    });
    $(selector + ' .dropdown').on('hide.bs.dropdown', function (e) {
      if (e.clickEvent) {
        e.preventDefault();
      } else {
        $('header.navbar').css('margin-bottom', '0');
      }
    });
    $(selector + ' .dropdown-menu').on('click.bs.dropdown', function (e) {
      const $anchor = $(e.target).find('a');
      if ($anchor.length) {
        e.stopPropagation();
        e.preventDefault();

        document.location.href = $anchor.attr('href');
      }
    });

    $(selector + ' .dropdown-item.active').each(function () {
      const $dropdownToggle = $(this).parents('.dropdown').find('.dropdown-toggle');
      $dropdownToggle.dropdown('show');
      $dropdownToggle.trigger('blur');
    });

    $(document).on('click', '.navbar-toggler[aria-expanded="false"]', function (e) {
      setTimeout(function () {
        $(selector + ' .nav-item.dropdown.active .dropdown-toggle').dropdown('show');
      }, 50); // hack to execute AFTER blur occured
    });
  }
};
