'use strict';

export const searchModal = {
  init: function (selector) {
    selector = selector ?? '#searchModal';
    window.addEventListener('load', this.setup(selector));
  },
  setup: function (selector) {
    $(selector).on('show.bs.modal', function () {
      $('body').addClass('search-modal');
    });

    if ($('html.ie-11').length == 0) {
      $(selector).on('shown.bs.modal', function () {
        $(selector + ' input[name="q"]').focus();
      });
    }

    $(selector).on('hidden.bs.modal', function () {
      $('body').removeClass('search-modal');
    });
  }
};
