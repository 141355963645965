const enquire = require('enquire.js');
const screenDownXS = 'screen and (max-width:575.98px)';
const screenUpSm = 'screen and (min-width:576px)';

const $pageTabs = $('#pageTabs');
const $pageTabsLi = $('#pageTabs > li');
const $pageDropdown = $('.pageTabs.dropdown-menu');
const $pageTabsLink = $('.pageTabs a[data-toggle="tab"]');

export const pageTabs = {
  init: function () {
    enquire.register(screenDownXS, {
      match: function () {
        pageTabs.tabDropdown();
      },
      unmatch: function () {
        pageTabs.tabDesktop();
      }
    });

    this.changeHash();
  },
  changeHash: function () {
    if (window.location.hash) {
      const $target = $('.pageTabs a[href="' + window.location.hash + '"]');

      if ($target.length) {
        // console.log('show',$target);
        $target.tab('show');
      }
    } else {
      $('.pageTabs li:first-child > a').tab('show');
    }

    $pageTabsLink.on('show.bs.tab', function (e) {
      location.hash = $(e.target).attr('href');
    });
  },
  tabDropdown: function () {
    // replace link classes for dropdown
    $pageTabsLi.find('a').addClass('dropdown-item');

    // replace li classes and move it to dropdown (mobile)
    $pageTabsLi.detach().appendTo($pageDropdown);

    $(document).on('show.bs.tab', $pageTabsLink, function (e) {
      // remove active class
      $pageDropdown.find('a[data-toggle="tab"]').removeClass('active').attr('aria-selected', false);
      $('#tabsDropdown').text($(e.target).text());
    });
  },
  tabDesktop: function () {
    // replace link classes for tabs
    $pageTabsLi.find('a').removeClass('dropdown-item');

    // replace li classes and move it to tabs (desktop)
    $pageTabsLi.detach().appendTo($pageTabs);
  }
};
