'use strict';
import Swiper, { Autoplay, Pagination } from 'swiper';

export const heroSlider = {
  init: function (selector) {
    selector = selector ?? 'hero-swiper';

    // Fix ie11 : Object doesn't support property or method isNaN
    Number.isNaN = Number.isNaN || function (value) {
      return typeof value === 'number' && isNaN(value);
    };

    Swiper.use([Pagination, Autoplay]);
    window.addEventListener('load', this.setup(selector));
  },
  setup: function (selector) {
    const selectorSwiper = `#${selector}`;
    const selectorSwiperPagination = `.${selector}-pagination`;
    const selectorSwiperPause = `.${selector}-pause`;
    const selectorSwiperPlay = `.${selector}-play`;

    const heroSwiper = new Swiper(selectorSwiper, {
      slidesPerView: 1,
      pagination: {
        el: selectorSwiperPagination,
        clickable: true
      },
      keyboard: {
        enabled: true,
        onlyInViewport: false
      },
      loop: true,
      autoplay: {
        delay: 7000,
        disableOnInteraction: true
      },
      on: {
        slideChange: function (swiper) {
          const slide = swiper.slides[swiper.activeIndex];
          const title = slide.getAttribute('data-hero-slider-title');
          const subtitle = slide.getAttribute('data-hero-slider-subtitle');
          const url = slide.getAttribute('data-hero-slider-url');

          const titleTarget = document.querySelector('[data-hero-slider-title-target]');
          const subtitleTarget = document.querySelector('[data-hero-slider-subtitle-target]');

          subtitleTarget.innerHTML = subtitle;

          if (url && url.trim() !== '') {
            const link = document.createElement('a');
            link.setAttribute('href', url);
            link.textContent = title;

            titleTarget.innerHTML = '';
            titleTarget.appendChild(link);
          } else {
            titleTarget.innerHTML = '';
            titleTarget.textContent = title;
          }
        }
      }
    });

    $(document).on('click', selectorSwiperPlay, function () {
      heroSwiper.autoplay.start();
      $(this).hide();
      $(selectorSwiperPause).show();
      $(selectorSwiperPause).focus();
      return false;
    });

    $(document).on('click', selectorSwiperPause, function () {
      heroSwiper.autoplay.stop();
      $(this).hide();
      $(selectorSwiperPlay).show();
      $(selectorSwiperPlay).focus();
      return false;
    });
  }
};
