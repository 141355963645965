'use strict';
import Swiper, { Autoplay, Pagination } from 'swiper';

export const barometerSlider = {
  init: function (selector) {
    selector = selector ?? '#barometer-swiper';

    // Fix ie11 : Object doesn't support property or method isNaN
    Number.isNaN = Number.isNaN || function (value) {
      return typeof value === 'number' && isNaN(value);
    };

    Swiper.use([Pagination, Autoplay]);
    window.addEventListener('load', this.setup(selector));
  },
  setup: function (selector) {
    const barometerSwiper = new Swiper(selector, {
      slidesPerView: 'auto',
      freeMode: true
    });

    barometerSwiper.on('progress', function (swiper, progress) {
      if (progress <= 0) {
        swiper.$el.removeClass('mask-beginning');
        swiper.$el.addClass('mask-end'); // account for first load
        $('.swiper-custom-navigation .swiper-prev').addClass('disabled');
      } else if (progress >= 1) {
        swiper.$el.removeClass('mask-end');
        $('.swiper-custom-navigation .swiper-next').addClass('disabled');
      } else {
        $('.swiper-custom-navigation .swiper-prev').removeClass('disabled');
        $('.swiper-custom-navigation .swiper-next').removeClass('disabled');
        swiper.$el.addClass('mask-beginning mask-end');
      }
    });

    $('.swiper-custom-navigation a').on('click', function () {
      const forward = !$(this).hasClass('swiper-prev');

      let nextTranslate = barometerSwiper.getTranslate();
      if (forward) {
        nextTranslate -= 500;
      } else {
        nextTranslate += 500;
      }

      barometerSwiper.translateTo(nextTranslate, 500);

      return false;
    });
  }
};
