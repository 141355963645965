'use strict';

export const offices = {
  init: function (selector) {
    selector = selector ?? '.offices select';
    window.addEventListener('load', this.setup(selector));
  },
  setup: function (selector) {
    const select = document.querySelector(selector);
    if (!select) {
      return;
    }

    select.addEventListener('change', (e) => {
      const selectedProvince = e.target.value;
      if (selectedProvince === '-1') {
        this.hideAllInfos();
        return false;
      }

      const infos = document.querySelectorAll('[data-province]');
      infos.forEach(function (info) {
        if (selectedProvince === info.getAttribute('data-province')) {
          return info.style.display = 'block';
        }

        return info.style.display = 'none';
      });
    });
  },
  hideAllInfos: function () {
    const infos = document.querySelectorAll('[data-province]');
    infos.forEach(function (info) {
      info.style.display = 'none';
    });
  }
};
