const collapseSelectors = [
  '.target-collapse',
  '.heading-collapsible'
];
const collapseWrapperPrototype = '<div class="collapse-wrapper"></div>';
const collapseContentWrapperPrototype = '<div class="collapse u-pl-9" id="collapsible-ID_NUM" aria-labelledby="collapse-toggler-ID_NUM"></div>';
const collapsePillPrototype = '<i class="collapse-pill"></i>';

export default function initCollapse () {
  function initCollapseToggler ($toggler, index) {
    const $anchor = $('<a/>');
    $anchor.attr({
      'data-toggle': 'collapse',
      href: '#collapsible-' + index,
      'aria-expanded': false,
      'aria-controls': '#collapsible-' + index,
      role: 'button',
      id: 'collapse-toggler-' + index
    });

    $toggler.replaceWith($anchor);
    $anchor.append($toggler);

    if (!$toggler.hasClass('heading-collapsible')) {
      $anchor.prepend($(collapsePillPrototype));
    } else {
      return $anchor;
    }
  }

  function prepareWrapperPrototype (index) {
    const preparedPrototype = collapseContentWrapperPrototype.replace(/ID_NUM/g, index);
    return $(preparedPrototype);
  }

  // allows each collapse element to have a unique ID
  let masterIndex = 0;

  // cycle through all possible collapse selectors
  collapseSelectors.forEach(function (selector) {
    if (selector === '.target-collapse') {
      // find elements in DOM
      const $collapsables = $(selector);

      // process each of them
      $collapsables.each(function () {
        // prepare wrapper with index
        const $wrapper = $(prepareWrapperPrototype(masterIndex));

        // cycle through children to find first element (toggler) and rest is wrapped inside collapse
        $(this).children().each(function (childIndex, element) {
          if (childIndex === 0) {
            initCollapseToggler($(this), masterIndex);
          } else {
            $wrapper.append($(this));
          }
        });

        $(this).append($wrapper);
        $(this).collapse('hide');
        masterIndex++;
      });
    }

    if (selector === '.heading-collapsible') {
      // find elements in DOM
      const $heading = $(selector);

      // process each of them
      $heading.each(function () {
        // prepare wrapper with index
        const $wrapper = $(prepareWrapperPrototype(masterIndex));

        // find nodeName (h2, h3, h4... ?)
        const hType = $(this).prop('nodeName');

        // get all content until next element with same nodeName
        const $content2Wrap = $(this).nextUntil(hType);

        // add link on heading
        const collapseLink = initCollapseToggler($(this).prepend($(collapsePillPrototype)), masterIndex);

        // wrap content inside collapse
        collapseLink
          .wrap(collapseWrapperPrototype)
          .after($wrapper.append($content2Wrap));

        masterIndex++;
      });
    }
  });
}
