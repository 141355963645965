'use strict';

import Cookies from 'js-cookie';

export const cookiesBanner = {
  init: function (selector, cookieName) {
    selector = selector ?? '#cookiesBanner';
    cookieName = cookieName ?? 'cookieBanner';
    window.addEventListener('load', this.setup(selector, cookieName));
  },
  setup: function (selector, cookieName) {
    if (Cookies.get(cookieName) === undefined) {
      $(selector).addClass('active');
    }
    $(document).on('click', selector + ' .close-banner', function () {
      $(selector).removeClass('active');
      Cookies.set(cookieName, true, { expires: 31 });
      return false;
    });
  }
};
