'use strict';

export const mobileNavigation = {
  init: function (selector) {
    selector = selector ?? '.js-change-location';
    window.addEventListener('load', this.setup(selector));
  },
  setup: function (selector) {
    const selects = document.querySelectorAll(selector);
    selects.forEach(function (select) {
      select.addEventListener('change', (e) => {
        document.location.href = e.target.value;
      });
    });
  }
};
